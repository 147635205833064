import React from "react"
import { CheckIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"
import { useI18next, useTranslation, Trans } from "gatsby-plugin-react-i18next"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
const product_features = [
  {
    fr: "Création de tests en illimité",
    en: "Unlimited tests",
  },
  {
    fr: "Tentatives de tests illimitées",
    en: "Unlimited test attempts",
  },
  {
    fr: "Jusqu'à 250 utilisateurs",
    en: "Up to 250 user",
  },
  {
    fr: "1 compte administrateur",
    en: "1 admin account",
  },
  {
    en: "Unlimited workspaces",
    fr: "Espaces de travails illimités",
  },
  {
    fr: "Jusqu'à 5GB de stockage",
    en: "Up to 5GB of storage",
  },
  {
    fr: "Programmation de la période des tests",
    en: "Test scheduling",
  },
  {
    fr: "Correction automatique",
    en: "Automatic grading",
  },
  {
    fr: "Notifications par email",
    en: "Email notifications",
  },
  {
    fr: "Extraction de rapports Excel",
    en: "Excel reports",
  },
]
export default function Pricing() {
  const { language } = useI18next()
  const { t } = useTranslation()
  return (
    <div className="bg-gradient-to-b from-primary-50 via-white to-white">
      {/* Pricing section with single price and feature list */}
      <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="pb-16 xl:flex xl:items-center xl:justify-between">
          <div>
            <h1 className="text-4xl font-bold tracking-tight sm:text-5xl">
              <span className="text-gray-900">{t("pricing_title")} </span>
              <span className="text-primary-600">
                {t("pricing_title_emphasized")}
              </span>
            </h1>
            <p className="mt-5 text-xl text-gray-500">
              {t("pricing_subtitle")}
            </p>
          </div>

          <Link
            to="contact"
            className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 py-3 px-5 text-base font-medium text-white hover:bg-primary-700 sm:mt-10 sm:w-auto xl:mt-0"
          >
            {t("header_cta")}
          </Link>
        </div>
        <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
          <div>
            <h2 className="text-lg font-semibold text-primary-600">
              <Trans>Everything you need</Trans>
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
              <Trans>All-in-one platform</Trans>
            </p>
            <p className="mt-4 text-lg text-gray-500">{t("pricing_details")}</p>
          </div>
          <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
            <ul role="list" className="divide-y divide-gray-200">
              {product_features.slice(0, 5).map((feature, featureIdx) => (
                <li
                  key={feature[language]}
                  className={classNames(
                    featureIdx === 0 ? "md:py-0 md:pb-4" : "",
                    "py-4 flex"
                  )}
                >
                  <CheckIcon
                    className="h-6 w-6 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3 text-base text-gray-500">
                    {feature[language]}
                  </span>
                </li>
              ))}
            </ul>
            <ul
              role="list"
              className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0"
            >
              {product_features.slice(5).map((feature, featureIdx) => (
                <li
                  key={feature[language]}
                  className={classNames(
                    featureIdx === 0 ? "md:border-t-0 md:py-0 md:pb-4" : "",
                    "py-4 flex"
                  )}
                >
                  <CheckIcon
                    className="h-6 w-6 flex-shrink-0 text-green-500"
                    aria-hidden="true"
                  />
                  <span className="ml-3 text-base text-gray-500">
                    {feature[language]}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
