import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { graphql, Link } from "gatsby"
import { MicrophoneIcon } from "@heroicons/react/outline"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Faq from "../sections/faq"
import BlogSection from "../sections/blog"
import Metrics from "../sections/metrics"
import Pricing from "../sections/pricing"
import Quiz from "../icons/quiz.svg"
import Essai from "../icons/essai.svg"
import Video from "../icons/video.svg"
import Proctoring from "../icons/proctoring_white.svg"
import Upload from "../icons/upload.svg"
import Schedule from "../icons/assign_test.svg"
import Create from "../icons/create_test.svg"

const features = [
  {
    name: { fr: "QCM/MRQ", en: "QCM/MRQ" },
    description: {
      fr: "Créez votre QCM, QCU, vrai ou faux",
      en: "Create your QCM, QCU, true or false",
    },
    icon: Quiz,
  },
  {
    name: { fr: "Vidéo/audio", en: "Video/audio" },
    description: {
      fr: "Insérez des vidéos ou des enregistrements audio que le candidat pourra regarder ou écouter",
      en: "Insert videos or audio recordings that the candidate can watch or listen to.",
    },
    icon: Video,
  },
  {
    name: { fr: "Enregistrement Vocale", en: "Voice recording" },
    description: {
      fr: "Permettez à vos candidats de s’exprimer à l’oral en enregistrant des vocaux que l’examinateur pourra écouter et noter par la suite",
      en: "Allow your candidates to express themselves by recording voices that the examiner can listen to and note down later",
    },
    icon: MicrophoneIcon,
  },
  {
    name: { fr: "Essai", en: "Writing" },
    description: {
      fr: "Permettez à vos candidats de s’exprimer à l’écrit sur des dissertations que l’examinateur pourra lire et noter par la suite",
      en: "Allow your candidates to express themselves in writing on essays that the examiner can read and mark afterwards",
    },
    icon: Essai,
  },
  {
    name: { fr: "Upload", en: "Upload" },
    description: {
      fr: "Donnez à vos candidats l’option d’uploader leurs copies, brouillons, et autres pour les visualiser ensuite sur votre espace admin",
      en: "Give your candidates the option to upload their copies, drafts, and others to view them later on your admin area",
    },
    icon: Upload,
  },
  {
    name: { fr: "Proctoring", en: "Proctoring" },
    description: {
      fr: "Demandez à vos candidats de déclencher leurs webcams pour comparer leurs identités à leurs CIN et les surveiller tout au long de leurs examens ",
      en: "Ask your candidates to trigger their webcams to compare their identities to their CINs and monitor them throughout their exams",
    },
    icon: Proctoring,
  },
]
const metrics = [
  {
    id: 1,
    stat: "20K+",
    emphasis: "Tests Administrés",
    rest: "use laoreet amet lacus nibh integer quis.",
  },
  {
    id: 2,
    stat: "9K+",
    emphasis: "Utilisateurs",
    rest: "lacus nibh integer quis.",
  },
  {
    id: 3,
    stat: "4,8/5",
    emphasis: "Satisfaction Utilisateur",
    rest: "laoreet amet lacus nibh integer quis.",
  },
  {
    id: 4,
    stat: "200+",
    emphasis: "Tests créés",
    rest: "lacus nibh integer quis.",
  },
]

export default function Index({ data }) {
  const { t } = useTranslation()
  const { language } = useI18next()
  const videoId = {
    en: {
      url: "ceaf2fb32462507171d64743ac0fc994",
      time: "19s",
    },
    fr: {
      url: "c281d9f8967a1d895fb40c2e45380a0f",
      time: "32s",
    },
  }
  const posts = data.allContentfulEasytestBlogPost.edges
  return (
    <Layout>
      {/* Hero section */}
      <Seo title="Design, organize and monitor your online tests" />
      <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h1>
              <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
                <span className="block text-gray-900">
                  <Trans>Votre partenaire</Trans>
                </span>
                <span className="block text-primary-600">
                  <Trans>de tests en ligne</Trans>
                </span>
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              {t("hero_section")}
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <Link
                to="contact"
                className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
              >
                {t("header_cta")}
              </Link>
            </div>
          </div>
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <svg
              className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
              width={640}
              height={784}
              fill="none"
              viewBox="0 0 640 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                  x={118}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                y={72}
                width={640}
                height={640}
                className="text-gray-50"
                fill="currentColor"
              />
              <rect
                x={118}
                width={404}
                height={784}
                fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
              />
            </svg>
            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-lg p-44">
              <iframe
                src={`https://iframe.videodelivery.net/${videoId[language].url}?poster=https%3A%2F%2Fvideodelivery.net%2F${videoId[language].url}%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D${videoId[language].time}%26height%3D600`}
                className="h-full w-full left-0 top-0 absolute border-none"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Gradient Feature Section */}
      <div className="bg-gradient-to-r from-secondary-800 to-primary-700 mt-24 relative">
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
          <h2 className="text-3xl font-extrabold text-white tracking-tight">
            {t("features_title")}
          </h2>
          <p className="mt-4 max-w-3xl text-lg text-secondary-200">
            {t("features_subtitle")}
          </p>
          <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map(feature => (
              <div key={feature.name[language]}>
                <div>
                  <span className="flex items-center justify-center h-16 w-16 rounded-md bg-white bg-opacity-10">
                    <feature.icon
                      className="h-12 w-12 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-white">
                    {feature.name[language]}
                  </h3>
                  <p className="mt-2 text-base text-secondary-200">
                    {feature.description[language]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Logo Cloud */}
      {/* <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
            {t("partners")}
          </p>
          <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-3 items-center">
            <div className="col-span-1 flex justify-center">
              <OCP />
            </div>
            <div className="col-span-1 flex justify-center">
              <ABS />
            </div>
            <div className="col-span-1 flex justify-center">
              <UM6P />
            </div>
          </div>
        </div>
      </div> */}

      {/* Alternating Feature Sections */}
      <div className="relative pt-16 overflow-hidden">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
        />
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-16 w-16 rounded-md flex items-center justify-center bg-white">
                    <Create
                      className="h-12 w-12 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    {t("afs_t_1")}
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">{t("afs_b_1")}</p>
                  {/* <div className="mt-6">
                    <a
                      href="#"
                      className="inline-flex bg-gradient-to-r from-secondary-600 to-primary-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-secondary-700 hover:to-primary-700"
                    >
                      Live Demo
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-8 lg:px-0 lg:m-0 lg:relative lg:h-full">
                {/* <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                  alt="Inbox user interface"
                /> */}
                <StaticImage
                  src="../images/screenshot_admin.png"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="Hero Image"
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                  <span className="h-16 w-16 rounded-md flex items-center justify-center bg-white">
                    <Schedule
                      className="h-12 w-12 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    {t("afs_t_2")}
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">{t("afs_b_2")}</p>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;{t("afs_testimonials")}&rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-6"
                          src="https://um6p.ma/sites/default/files/logo.svg"
                          alt=""
                        />
                      </div>
                      <div className="text-base font-medium text-gray-700">
                        Meryem Maknassi, Language Lab Teaching Assistant, UM6P
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <StaticImage
                  src="../images/manage_group.png"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="Hero Image"
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Metrics />
      <Pricing />
      {/* <Faq /> */}
      <BlogSection posts={posts} />

      {/* CTA section */}
      <div className="bg-primary-50">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-24 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-primary-900 sm:text-4xl">
            <span className="block">
              <Trans>Ready to dive in</Trans>?
            </span>
            <span className="block text-primary-600">
              <Trans>Start for free today</Trans>.
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="contact"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 py-3 px-5 text-base font-medium text-white hover:bg-primary-700"
              >
                <Trans>Commencer</Trans>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      {/* <div className="bg-gradient-to-r from-secondary-200 to-primary-300">
        <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">
              <Trans>Vous êtes à la recherche d'une offre personnalisée</Trans>
            </span>
            <span className="block bg-gradient-to-r from-secondary-600 to-primary-700 bg-clip-text text-transparent">
              <Trans>répondant à vos besoins très spécifiques</Trans> ?
            </span>
          </h2>
          <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
            <Link
              to="contact"
              className="flex items-center justify-center bg-gradient-to-r from-secondary-600 to-primary-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-secondary-700 hover:to-primary-700"
            >
              <Trans>Contactez-nous</Trans>
            </Link>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulEasytestBlogPost(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          author {
            name
            image {
              gatsbyImageData(
                width: 50
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
