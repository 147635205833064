import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ReactHtmlParser from "react-html-parser"

export default function Postcard({ post }) {
  const image = getImage(post.node.heroImage)
  const author_image = getImage(post.node.author.image)

  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0">
        <GatsbyImage
          className="h-96 w-full object-cover"
          image={image}
          alt=""
        />
        {/* <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" /> */}
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium text-indigo-600">
            <a href="#" className="hover:underline">
              Article
            </a>
          </p>
          <Link to={`/blog/${post.node.slug}`} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">
              {post.node.title}
            </p>
            <p className="mt-3 text-base text-gray-500">
              {ReactHtmlParser(post.node.description.childMarkdownRemark.html)}
            </p>
          </Link>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <a href="#">
              <span className="sr-only">{post.node.author.name}</span>
              {/* <img
                className="h-10 w-10 rounded-full"
                src={post.author.imageUrl}
                alt=""
              /> */}
              <GatsbyImage
                className="h-10 w-10 rounded-full"
                image={author_image}
                alt=""
              />
            </a>
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">
              <a href="#" className="hover:underline">
                {post.node.author.name}
              </a>
            </p>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time dateTime={post.datetime}>{post.date}</time>
              {/* <span aria-hidden="true">&middot;</span> */}
              {/* <span>{post.readingTime} read</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
